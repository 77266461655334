<template>
  <form @submit.prevent="saveStaffDetails" class="flex flex-wrap">
    <div class="w-full py-2">
      <label class="uppercase font-semibold text-sm"
        >First Name<span class="text-red-400">*</span></label
      >
      <input
        v-model="staffDetails.first_name"
        class="bge-input bge-input-spacing rounded w-full"
        type="text"
        required
        placeholder="First Name..."
      />
    </div>
    <div class="w-full py-2">
      <label class="uppercase font-semibold text-sm">Middle Name</label>
      <input
        v-model="staffDetails.middle_name"
        class="bge-input bge-input-spacing rounded w-full"
        type="text"
        required
        placeholder="Middle Name..."
      />
    </div>
    <div class="w-full py-2">
      <label class="uppercase font-semibold text-sm"
        >Last Name<span class="text-red-400">*</span></label
      >
      <input
        v-model="staffDetails.last_name"
        class="bge-input bge-input-spacing rounded w-full"
        type="text"
        required
        placeholder="Last Name..."
      />
    </div>
    <div class="w-full py-2">
      <label class="uppercase font-semibold text-sm"
        >Job Title<span class="text-red-400">*</span></label
      >
      <input
        v-model="staffDetails.job_title"
        class="bge-input bge-input-spacing rounded w-full"
        type="text"
        required
        placeholder="job Title..."
      />
    </div>
    <div class="w-full py-2">
      <label class="uppercase font-semibold text-sm"
        >Department<span class="text-red-400">*</span></label
      >
      <div class="inline-block relative w-full">
        <select
          v-model="staffDetails.department"
          class="bge-input bge-select rounded"
          multiple
        >
          <template v-for="(department, i) in departments">
            <option :key="i" :value="department">{{ department }}</option>
          </template>
        </select>
      </div>
    </div>
    <div class="w-full py-2">
      <label class="uppercase font-semibold text-sm"
        >Work Email<span class="text-red-400">*</span></label
      >
      <input
        v-model="staffDetails.email"
        class="bge-input bge-input-spacing rounded w-full"
        type="text"
        required
        placeholder="Email..."
      />
    </div>
    <div class="w-full py-2">
      <label class="uppercase font-semibold text-sm"
        >Mobile No<span class="text-red-400">*</span></label
      >
      <input
        v-model="staffDetails.mobile"
        class="bge-input bge-input-spacing rounded w-full"
        type="text"
        required
        placeholder="Mobile..."
        :disabled="isReadOnly"
      />
    </div>
    <div class="w-full py-2">
      <label class="uppercase font-semibold text-sm"
        >Personal Email<span class="text-red-400">*</span></label
      >
      <input
        v-model="staffDetails.personal_email"
        class="bge-input bge-input-spacing rounded w-full"
        type="text"
        required
        placeholder="Personal Email..."
        :disabled="isReadOnly"
      />
    </div>
    <div class="w-full py-2">
      <label class="uppercase font-semibold text-sm">Telephone No</label>
      <input
        v-model="staffDetails.telephone"
        class="bge-input bge-input-spacing rounded w-full"
        type="text"
        required
        placeholder="Telephone No..."
      />
    </div>
    <div class="w-full py-2">
      <label class="uppercase font-semibold text-sm"
        >Address 1<span class="text-red-400">*</span></label
      >
      <input
        v-model="staffDetails.address1"
        class="bge-input bge-input-spacing rounded w-full"
        type="text"
        required
        placeholder="Address 1..."
        :disabled="isReadOnly"
      />
    </div>
    <div class="w-full py-2">
      <label class="uppercase font-semibold text-sm">Address 2</label>
      <input
        v-model="staffDetails.address2"
        class="bge-input bge-input-spacing rounded w-full"
        type="text"
        required
        placeholder="Address 2..."
        :disabled="isReadOnly"
      />
    </div>
    <div class="w-full py-2">
      <label class="uppercase font-semibold text-sm">Address 3</label>
      <input
        v-model="staffDetails.address3"
        class="bge-input bge-input-spacing rounded w-full"
        type="text"
        required
        placeholder="Address 3..."
        :disabled="isReadOnly"
      />
    </div>
    <div class="w-full py-2">
      <label class="uppercase font-semibold text-sm"
        >City<span class="text-red-400">*</span></label
      >
      <input
        v-model="staffDetails.city"
        class="bge-input bge-input-spacing rounded w-full"
        type="text"
        required
        placeholder="City..."
        :disabled="isReadOnly"
      />
    </div>
    <div class="w-full py-2">
      <label class="uppercase font-semibold text-sm">County</label>
      <input
        v-model="staffDetails.county"
        class="bge-input bge-input-spacing rounded w-full"
        type="text"
        required
        placeholder="County..."
        :disabled="isReadOnly"
      />
    </div>
    <div class="w-full py-2">
      <label class="uppercase font-semibold text-sm"
        >Postcode<span class="text-red-400">*</span></label
      >
      <input
        v-model="staffDetails.postcode"
        class="bge-input bge-input-spacing rounded w-full"
        type="text"
        required
        placeholder="Postcode..."
        :disabled="isReadOnly"
      />
    </div>
    <div class="w-full py-2">
      <label class="uppercase font-semibold text-sm"
        >Country<span class="text-red-400">*</span></label
      >
      <div class="inline-block relative w-full">
        <select
          v-model="staffDetails.country"
          class="bge-input bge-select rounded"
        >
          <optgroup label="Frequently Used">
            <option value="United Kingdom">United Kingdom</option>
            <option value="Kenya">Kenya</option>
            <option value="England">England</option>
            <option value="Scotland">Scotland</option>
            <option value="Wales">Wales</option>
            <option value="Northern Ireland">Northern Ireland</option>
            <option value="Republic of Ireland">Republic of Ireland</option>
          </optgroup>
          <optgroup label="All Countries">
            <template v-for="(country, i) in countries">
              <option :key="i" :value="country">{{ country }}</option>
            </template>
          </optgroup>
        </select>
        <div
          class="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700"
        >
          <svg class="fill-current h-4 w-4" viewBox="0 0 20 20">
            <path
              d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"
            />
          </svg>
        </div>
      </div>
    </div>
    <!-- <div class="w-full py-2">
      <label class="uppercase font-semibold text-sm">Website</label>
      <input
        v-model="prospectDetails.website"
        class="bge-input bge-input-spacing rounded w-full"
        type="text"
        required
        placeholder="Website..."
      />
    </div> -->
    <div class="w-full">
      <is-authorized :permissions="['update:staff']">
        <button
          type="button"
          @click="saveStaffDetails"
          class="flex items-center float-right mt-5 rounded border bg-green-200 border-green-400 text-green-800 py-1 px-3 hover:bg-green-300 hover:border-green-500 hover:shadow active:shadow-inner transition duration-100"
        >
          <svg
            class="stroke-current h-5 w-5 mr-2"
            fill="none"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
            viewBox="0 0 24 24"
          >
            <path
              d="M19 21H5a2 2 0 01-2-2V5a2 2 0 012-2h11l5 5v11a2 2 0 01-2 2z"
            />
            <path d="M17 21v-8H7v8M7 3v5h8" />
          </svg>
          <span>Update Staff Details</span>
        </button>
      </is-authorized>
    </div>
  </form>
</template>

<script>
import { COUNTRIES } from "@/utils/constants";
import { DEPARTMENTS } from "@/utils/constants";
import Notification from "@/components/Notification.vue";
const IsAuthorized = () => import("@/components/Auth/IsAuthorized.vue");
import auth from "@/utils/auth";
export default {
  name: "EditStaffDetails",
  components: {
    Notification,
    IsAuthorized,
  },
  data() {
    return {
      countries: [],
      departments: [],
      isReadOnly: false,
    };
  },
  props: {
    staffDetails: {
      required: true,
      type: Object,
    },
  },
  mounted() {
    this.countries = COUNTRIES;
    this.departments = DEPARTMENTS;
    this.isReadOnly = !auth(["secret:staff"]);
  },
  methods: {
    saveStaffDetails: function () {
      let msg;
      if (!this.staffDetails.first_name) {
        msg = "Please enter first name.";
      } else if (!this.staffDetails.last_name) {
        msg = "Please enter last name.";
      } else if (!this.staffDetails.job_title) {
        msg = "Please enter job title.";
      } else if (!this.staffDetails.email) {
        msg = "Please enter email.";
      } else if (!this.$validator.isEmail(this.staffDetails.email)) {
        msg = "Please enter valid email.";
      } else if (auth(["secret:staff"]) && !this.staffDetails.mobile) {
        msg = "Please enter mobile.";
      } else if (auth(["secret:staff"]) && !this.staffDetails.personal_email) {
        msg = "Please enter personal email.";
      } else if (
        auth(["secret:staff"]) &&
        !this.$validator.isEmail(this.staffDetails.personal_email)
      ) {
        msg = "Please enter valid personal email.";
      } else if (
        auth(["secret:staff"]) &&
        !this.$validator.isMobilePhone(this.staffDetails.mobile, "en-GB")
      ) {
        msg = "Please enter valid mobile, ex. 074XXXXXXXX";
      } else if (auth(["secret:staff"]) && !this.staffDetails.postcode) {
        msg = "Please enter postcode.";
      } else if (
        auth(["secret:staff"]) &&
        !this.$validator.isPostalCode(this.staffDetails.postcode, "GB")
      ) {
        msg = "Please enter valid postcode.";
      } else if (!this.staffDetails.address1) {
        msg = "Please enter address1.";
      } else if (!this.staffDetails.city) {
        msg = "Please enter city.";
      } else if (!this.staffDetails.country) {
        msg = "Please enter country.";
      }

      if (msg) {
        return this.$breadstick.notify(
          ({ h, onClose }) => {
            return h(
              Notification,
              {
                props: {
                  type: "warning",
                  title: "Error",
                  close: onClose,
                },
              },
              msg,
            );
          },
          {
            position: "top-right",
          },
        );
      }

      this.$emit("complete");
    },
  },
};
</script>
